<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul v-if="loggedUser" class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          :collapse="false"
          collapse-ref="/"
          nav-text="Home"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="HomeGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          :collapse="false"
          collapse-ref="/dashboard"
          nav-text="Dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="HomeGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'admin'"
          nav-text="Users"
          :collapse="false"
          collapse-ref="/users/list"
          :class="getRoute() === 'users' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <CreditCard />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'user'"
          nav-text="Browse Gnomes"
          :collapse="false"
          collapse-ref="/gnome/list"
          :class="getRoute() === 'gnome' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="BrowseGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'user'"
          nav-text="Found a Gnome"
          :collapse="false"
          collapse-ref="/gnome-verification"
          :class="getRoute() === 'gnome-verification' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="foundGnomeIcon" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'admin' || loggedUser.role === 'moderator'"
          nav-text="Gnome"
          collapse-ref="gnome"
          :class="getRoute() === 'gnome' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="BrowseGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                v-if="loggedUser.role !== 'moderator'"
                :to="{ name: 'GnomeRegistration' }"
                mini-icon="D"
                text="Registration"
              />
              <sidenav-item
                v-if="loggedUser.role !== 'moderator'"
                :to="{ name: 'GnomeList' }"
                mini-icon="A"
                text="Gnome List"
              />
              <sidenav-item
                v-if="loggedUser.role === 'admin' || loggedUser.role === 'moderator'"
                :to="{ name: 'GnomeFlaggedPostListPage' }"
                mini-icon="D"
                text="Flagged List"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="ecommerceExamples"
          nav-text="Ecommerce"
          :class="getRoute() === 'ecommerce' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="EcommerceGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <!-- <sidenav-item
                :to="{ name: 'Overview' }"
                mini-icon="O"
                text="Overview"
              /> -->

              <sidenav-collapse-item
                refer="productsExample"
                mini-icon="P"
                text="Products"
              >
                <template #nav-child-item>
                  <sidenav-item
                    v-if="loggedUser.role === 'admin'"
                    :to="{ name: 'New Product' }"
                    mini-icon="N"
                    text="New Product"
                  />
                  <!-- <sidenav-item
                    :to="{ name: 'Edit Product' }"
                    mini-icon="E"
                    text="Edit Product"
                  />
                  <sidenav-item
                    :to="{ name: 'Product Page' }"
                    mini-icon="P"
                    text="Product page"
                  /> -->
                  <sidenav-item
                    :to="{ name: 'HomePage' }"
                    mini-icon="P"
                    text="Home Page"
                  />
                  <sidenav-item
                    :to="{ name: 'CartPage' }"
                    mini-icon="P"
                    text="Cart"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-collapse-item
                refer="ordersExample"
                mini-icon="O"
                text="Orders"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Order List' }"
                    mini-icon="O"
                    text="Order List"
                  />
                </template>
              </sidenav-collapse-item>
              <!-- <sidenav-item
                :to="{ name: 'Referral' }"
                mini-icon="R"
                text="Referral"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'user'"
          nav-text="How it Works"
          :collapse="false"
          :class="page === 'how-it-works' ? 'active' : ''"
          @click="showFAQ('how-it-works')"
        >
          <template #icon>
            <img :src="ReadingGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'user'"
          nav-text="FAQ"
          :collapse="false"
          :class="page === 'faq' ? 'active' : ''"
          @click="showFAQ('faq')"
        >
          <template #icon>
            <img :src="ReadingGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="loggedUser.role === 'admin'" class="nav-item">
        <sidenav-collapse
          nav-text="Custom Ads"
          collapse-ref="customAds"
          :class="getRoute() === 'custom-ads' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <CreditCard />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'CustomAdsRegistration' }"
                mini-icon="D"
                text="Registration"
              />
              <sidenav-item
                :to="{ name: 'CustomAdsList' }"
                mini-icon="A"
                text="Ads List"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          v-if="loggedUser.role === 'admin'"
          nav-text="Points"
          :collapse="false"
          collapse-ref="/user-earnings/criteria"
          :class="getRoute() === 'user-earnings' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <CreditCard />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Share Referral Code"
          :collapse="false"
          data-bs-toggle="modal"
          data-bs-target="#referralModal"
        >
        <template #icon>
            <img :src="Referral" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Contact Us"
          :collapse="false"
          collapse-ref="/contact-us"
          :class="getRoute() === 'contact-us' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="ReadingGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Legal"
          :collapse="false"
          collapse-ref="/privacy-policy"
          :class="getRoute() === 'privacy-policy' ? 'active' : ''"
          @click="changeLogo()"
        >
          <template #icon>
            <img :src="ReadingGnome" alt="Custom Icon" style="width: 20px; height: 20px;" />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import ProfileService from "../../services/api/user/ProfileService"
import utils from "../../utils";
import { mapState } from "vuex";
import foundGnomeIcon from "../../assets/img/found-gnome.png";
import BrowseGnome from '../../assets/img/browse-gnome.png';
import EcommerceGnome from '../../assets/img/ecommerce-gnome.png';
import ReadingGnome from '../../assets/img/reading-gnome.png';
import HomeGnome from '../../assets/img/home.png'
import Referral from '../../assets/img/referralgnome.png'
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    CreditCard
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  emits: ['change-logo'],
  data() {
    return {
      loggedUser: null,
      page: '',
      foundGnomeIcon,
      BrowseGnome,
      EcommerceGnome,
      ReadingGnome,
      HomeGnome,
      Referral
    }
  },
  computed: {
    ...mapState(["isRTL"]),
    urlSegment() {
      const path = window.location.pathname;
      return path
    }

  },
  created() {
    if (utils.isLoggedIn()) this.getLoggedUser()
  },
  methods: {
    changeLogo () {
      this.$emit('change-logo')
    },
    showFAQ(page) {
      this.page = page
      this.urlSegment = ''
      if (page === 'faq') {
        this.$router.push({ name: 'HowItWorks', query: { section: 'faq' }})
      }else if (page === 'how-it-works') {
        this.$router.push({ name: 'HowItWorks', query: { section: 'main' }})
      }
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    async getLoggedUser() {
        try {
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status
          }

        } catch (error) {
          console.log('Get user error: ', error)
        }
      }
  },
};
</script>
