<template>
    <div>
      <!-- Large Scrollable Modal -->
      <div 
        id="termsModal" 
        class="modal fade" 
        tabindex="-1" 
        role="dialog" 
        aria-labelledby="termsModalLabel" 
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="termsModalLabel" class="modal-title">Terms of Use and Privacy Policy</h5>
              <button 
                type="button" 
                class="btn-close text-dark" 
                data-bs-dismiss="modal" 
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- Terms Content -->
              <h6 class="fw-bold">Last Updated: November 10, 2024</h6>
  
              <h5>1. Acceptance of Terms</h5>
              <p>
                Welcome to <strong>GnomeVoyage.com</strong> ("the Website"), owned and operated by Orange Pony Tech LLC 
                ("we," "us," or "our"), a company based in Oregon. By accessing or using this Website, 
                you agree to comply with these Terms of Use and the Privacy Policy included here. If you 
                do not agree, please do not use the Website.
              </p>
  
              <h5>2. Eligibility</h5>
              <p>
                This Website is intended for users who are at least 13 years of age. By using the Website, 
                you confirm that you are at least 13 years old. Users under the age of 13 are prohibited 
                from using the Website.
              </p>
  
              <h5>3. Content and Moderation</h5>
              <p>
                GnomeVoyage.com is designed to share and celebrate creative gnome adventures. While we use 
                moderation to prevent the display of inappropriate content, we cannot guarantee all offensive 
                material will be filtered. By using the Website, you understand that you may be exposed to 
                content that is unintended or offensive. Orange Pony Tech LLC is not liable for any exposure 
                to such content.
              </p>
  
              <h5>4. User Conduct</h5>
              <p>You agree to:</p>
              <ul>
                <li>Use the Website in a lawful, respectful, and responsible manner.</li>
                <li>Not post any content that is defamatory, offensive, harassing, obscene, or otherwise objectionable.</li>
                <li>Not post any content that infringes on the intellectual property rights of others.</li>
                <li>Not engage in any activity that could disrupt or impair the operation of the Website.</li>
              </ul>
              <p>
                We reserve the right to remove any content deemed inappropriate or in violation of these 
                terms without notice.
              </p>
  
              <h5>5. Intellectual Property and Takedown Requests</h5>
              <p>
                If you believe your intellectual property has been infringed on the Website, please contact 
                us via the <strong>Contact Us</strong> page. We will review and, if appropriate, take action in 
                compliance with applicable laws.
              </p>
  
              <h5>6. Limitation of Liability</h5>
              <p>
                To the fullest extent permitted by law, Orange Pony Tech LLC, its employees, officers, 
                directors, and agents will not be liable for any direct, indirect, incidental, special, 
                consequential, or punitive damages arising from your use of the Website, even if we have 
                been advised of the possibility of such damages.
              </p>
  
              <h5>7. Indemnification</h5>
              <p>
                You agree to indemnify, defend, and hold harmless Orange Pony Tech LLC, its affiliates, 
                employees, officers, and directors from and against any and all claims, liabilities, 
                damages, losses, or expenses (including reasonable attorneys' fees) arising from your 
                use of the Website, your violation of these Terms of Use, or your violation of any 
                third-party rights.
              </p>
  
              <h5>8. Arbitration and Dispute Resolution</h5>
              <p>
                Any disputes arising out of or relating to these Terms of Use or your use of the Website 
                shall be resolved through binding arbitration in accordance with Orange Pony Tech LLC’s 
                arbitration policy. You waive your right to bring any disputes before a court of law 
                and agree that arbitration will be the exclusive remedy for any claims.
              </p>
  
              <h5>9. Privacy Policy Notice</h5>
              <p><strong>Information We Collect:</strong></p>
              <ul>
                <li>
                  We collect basic user information such as name, email address, and other contact 
                  details voluntarily provided by you for account creation, customer support, or inquiries.
                </li>
                <li>
                  <strong>Cookies and Tracking:</strong> This Website uses Google AdSense to display 
                  third-party advertisements. Google may collect information about your browsing behavior.
                </li>
              </ul>
              <p>
                You can opt out of personalized advertising by visiting 
                <a href="https://adssettings.google.com" target="_blank">Google Ads Settings</a>.
              </p>
              <p>
                For more detailed information, please review the full Privacy Policy included here.
              </p>
            </div>
            <div class="modal-footer">
              <button 
                type="button" 
                class="btn btn-primary" 
                data-bs-dismiss="modal"
              >
                Got It!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  

<script>
export default {
    name: "PopupSharePost",
    props:{
      show:{
        type: Boolean,
        default: false
      }
    },
    emits: ['close-modal'],
    data() {
        return {
        }
    },
    mounted () {
    },
    methods: {
      closeModal () {
        this.$emit('close-modal')
      }
    }
};
</script>

<style>
.modal-form-size{
  max-width: 100%;
}

.modal-backdrop {
    width: auto !important;
}
</style>