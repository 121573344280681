<template>
    <div class="container-fluid py-4">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-sm-12 col-lg-8">
          <div class="multisteps-form mb-5">
            <div class="row">
              <div class="col-12 col-lg-8 m-auto">
                <form class="multisteps-form__form mb-5">
                  <!--single form panel-->
                  <gnome-authentication-info
                    :is-loading="isLoading"
                    class="js-active position-relative"
                    @gnome-authentication="verification"
                  />
                  <soft-alert v-if="showAlert" :color="colorAlert" :dismissible="false" :icon="iconAlert">
                    {{ msgAlert }}
                  </soft-alert>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-post
        :show="showCreatePostPopup"
        @close-modal="$store.state.showSidenav = true"
        @create-post="createPost"
      />
    </div>
    <button id="openCreatePostModal" class="d-none" data-bs-toggle="modal"
    data-bs-target="#modalCreatePost" @click="createPostClicked"></button>
  </template>
  
  <script>
  import GnomeAuthenticationInfo from "./components/GnomeAuthenticationInfo.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import utils from "../../../utils";
  import CreatePost from "../posting/components/CreatePost.vue";

  import GnomeService from "../../../services/api/gnome/GnomeService"
  import GnomePostingService from "../../../services/api/gnome/GnomePostingService.js";
  import FileConverterMixin from "@/mixins/FileConverterMixin.js";

  export default {
    name: "GnomeAuthenticationPage",
    components: {
      GnomeAuthenticationInfo,
      SoftAlert,
      CreatePost
    },
    mixins:[FileConverterMixin],
    data() {
      return {
        showMenu: false,
        showAlert: false,
        colorAlert: '',
        iconAlert: '',
        msgAlert: '',
        isLoading: false,
        showCreatePostPopup: false,
        verificationData: {
          gnome_verification_token: null,
          gnome_id: null,
          code: null
        }
      };
    },
    mounted () {
      this.$store.state.showSidenav = utils.isLoggedIn()
      this.$store.state.showNavbar = utils.isLoggedIn()
      utils.removeGnomeToken()
    },
    methods: {
      openModal() {
            const modalButton = document.getElementById("openCreatePostModal");
            if (modalButton) {
                modalButton.click();
            }
        },
      createPostClicked() {
        this.$store.state.showSidenav = false
        this.showCreatePostPopup = true
      },
      async createPost (data) {
        try {
          this.$swal.showLoading();

          // this.$store.state.showSidenav = true

          const attachment = await this.readFileAsBase64(data.attachment)

          const payload = {
            title: data.title,
            description: data.description,
            image: attachment,
            gnome_id: this.verificationData.gnome_id,
            gnome_verification_token: this.verificationData.gnome_verification_token,
            gnome_verification_code: this.verificationData.code,
            latitude: data.latitude,
            longitude: data.longitude,
            address: data.address
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.createPost(payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully post created...!",
            type: 'success-message',
          }).then(() => {
            window.location.href = this.$router.resolve({
                name: 'GnomeSocialPage',
                params: { id: response.data.gnome_id }
            }).href
          })
        } catch (error) {
          console.log('Gnome Create Posting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else if((error.response.status === 403)) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async verificationWithId (payload) {
        try {
          this.isLoading = true
          this.showAlert = false

          const gnomeService = new GnomeService()
          const response = await gnomeService.verification(this.$route.params.id, payload)

          this.showAlert = true
          if (response.data.verification_status) {
            utils.removeGnomeToken()
            utils.setGnomeToken(JSON.stringify(response.data,null,2))
            this.colorAlert = 'success'
            this.iconAlert = 'fa fa-check-circle'
            this.msgAlert = 'Verified...!'

            setTimeout(() => {
              this.$router.push({ name: 'GnomeSocialPage', params: { id: this.$route.params.id }})
            }, 1500);
          }
          else{
            this.colorAlert = 'danger'
            this.msgAlert = 'please try again...!'
          }
          this.isLoading = false
        } catch (error) {
          console.log('Gnome verification Error: ', error)
          this.msgAlert = 'Something went wrong...!'
          this.showAlert = true
          this.colorAlert = 'danger'
          this.isLoading = false
        }
      },
      async verificationWithoutId (payload) {
        try {
          this.isLoading = true
          this.showAlert = false

          const gnomeService = new GnomeService()
          const response = await gnomeService.verificationWithoutId(payload.gnome_code)
          this.verificationData.code = payload.code

          this.showAlert = true
          if (response.data.verification_status) {
            this.verificationData.gnome_verification_token = response.data.gnome_verification_token
            this.verificationData.gnome_id = String(response.data.gnome_id)
            this.verificationData.code = payload.code
            utils.removeGnomeToken()
            utils.setGnomeToken(JSON.stringify(response.data,null,2))
            this.colorAlert = 'success'
            this.iconAlert = 'fa fa-check-circle'
            this.msgAlert = 'Verified...!'

            setTimeout(() => {
              this.openModal()
              // this.$router.push({ name: 'GnomeSocialPage', params: { id: response.data.gnome_id }})
            }, 1500);
          }
          else{
            utils.removeGnomeToken()
            this.colorAlert = 'danger'
            this.msgAlert = 'please try again...!'
          }
          this.isLoading = false
        } catch (error) {
          console.log('Gnome verification Error: ', error)
          this.msgAlert = 'Something went wrong..! Please check your code again.'
          this.showAlert = true
          this.colorAlert = 'danger'
          this.isLoading = false
        }
      },
      async verification (payload) {
        const routeArr = this.$route.path.split("/")
        if (routeArr[1] === 'gnome-verification') {
          await this.verificationWithoutId(payload)
        }
        else if (routeArr[2] === 'gnome-authentication') {
          await this.verificationWithId(payload)
        }
      }
    },
  };
  </script>
  