import BaseAPI from '../BaseAPI'
import utils from '../../../utils'

/**
 * AuthService Class that can be use to connect the auth apis
 */
export default class AuthService extends BaseAPI {
  signIn (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'login']

      this.post(payload, pathArr).then((response) => {
        utils.setLocalToken(response.data.access_token)
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  signUp (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'register']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateUserOnboard (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'user-profile', 'update']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  sendResetLink (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'password', 'forgot-password']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  resetPassword (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'password', 'reset']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  uploadProfilePicture (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'user', 'update', 'profile-pictures']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  verifyEmail (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'verify-email']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  resendVerification (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'resend-verification-code']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}