import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class PointsService extends BaseAPI {
  getUserPointsByUserId(userId) {
    return new Promise((resolve, reject) => {
      const pathArr = ['user-points', userId]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getUserPointsCriteria() {
    return new Promise((resolve, reject) => {
      const pathArr = ['points-criteria']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updatePointsCriteria (id, payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['points-criteria', id]
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getReferralPointsByUserId (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['points-by-action']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}