import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class GnomePostingService extends BaseAPI {
  createPost (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['create_post']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateLocation (id,payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['gnome_posts', id, 'update-location']
      console.log('payload', payload)

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updatePost (id,payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['edit_post', `${id}`]

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPostsByGnomeId (id, page = null,userId) {
    return new Promise((resolve, reject) => {
        const pathArrPrams = ['?']

        if (userId > 0) {
          pathArrPrams.push(`user_id=${userId}`)
        }

        if (page) {
          pathArrPrams.push(`page=${page}`)
        }

        const pathArr = ['get_gnome_posts', `${id}${this._getPathQueryParams(pathArrPrams)}` ]

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPostsUserListByGnomeId (id, limit, page) {
    return new Promise((resolve, reject) => {
        const pathArrPrams = ['?']

        if (page > 0) {
          pathArrPrams.push(`page=${page}`)
        }

        if (limit > 0) {
          pathArrPrams.push(`limit=${limit}`)
        }

        const pathArr = ['get_gnome_post_users', `${id}${this._getPathQueryParams(pathArrPrams)}` ]

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPostByPostId (id) {
    return new Promise((resolve, reject) => {

        const pathArr = ['get_gnome_post_by_id', `${id}` ]

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  createComment (payload) {
    return new Promise((resolve, reject) => {

      const pathArr = [`post_comment`]

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  editComment (payload) {
    return new Promise((resolve, reject) => {

      const pathArr = [`edit_comment`]

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getCommentsByPostId (id,commentPage) {
    return new Promise((resolve, reject) => {

      const queryParms = []

      if (commentPage > 0) queryParms.push(`page=${commentPage}`)

      const pathArr = ['get_comments_by_post_id', `${id}?${this._getPathQueryParams(queryParms)}` ]

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deletePostByPostId (id) {
    return new Promise((resolve, reject) => {

      const pathArr = ['delete_post', `${id}` ]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteCommentByCommentId (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['delete_comment', `${id}` ]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }


  likePost(id){
    return new Promise((resolve, reject) => {
      const pathArr = ['post', `reactions`]
      const payload = {
        gnome_post_id:id
      }
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  dislikePost(id){
    return new Promise((resolve, reject) => {
      const pathArr = ['post', `reactions`]
      const payload = {
        gnome_post_id:id
      }
      this.delete(pathArr,payload).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPostFlaggedReasons () {
    return new Promise((resolve, reject) => {

      const pathArr = ['report_categories']

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getFlaggedPostList () {
    return new Promise((resolve, reject) => {

      const pathArr = ['reports']

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  postFlagged (payload) {
    return new Promise((resolve, reject) => {

      const pathArr = ['posts', 'report']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  flaggedPostBlockUnblock (params) {
    return new Promise((resolve, reject) => {

      const pathArr = [`toggle_post_active_status?gnome_post_id=${params.gnome_post_id}&status=${params.status}`]

      this.post(params, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPostFlaggedList (id) {
    return new Promise((resolve, reject) => {

      const pathArr = ['post_reports', id]

      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
