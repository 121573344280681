<template>
    <div
      id="referralModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="referralModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="termsModalLabel" class="modal-title">Your Referral Link</h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label v-if="referralLink" for="referralLink" class="form-label">Referral Link:</label>
            <div v-if="referralLink" class="input-group">
              <input
                id="referralLink"
                type="text"
                class="form-control"
                :value="formattedReferralLink"
                readonly
              />
              <button class="btn btn-outline-secondary" @click="copyclipReferralLink">
                <i class="fas fa-copy"></i>
              </button>
            </div>
            <div class="text-center form-label"><h5 v-if="!referralLink">Sorry! You have an old account. No Referral link genrated your account</h5></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import ProfileService from "../../../services/api/user/ProfileService"
export default {
    name: 'ReferralPopup',
    data () {
        return {
            referralLink: '',
        }
    },
    computed: {
    formattedReferralLink() {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      return `${baseUrl}` + this.referralLink;
    },
  },
    mounted () {
        this.copyReferralLink()
    },
    methods: {
        copyclipReferralLink() {
            if (this.referralLink) {
                navigator.clipboard
                .writeText(this.formattedReferralLink)
                .then(() => {
                    alert("Referral link copied to clipboard!");
                })
                .catch((err) => {
                    console.error("Failed to copy referral link: ", err);
                });
            } else {
                alert("No referral link to copy!");
            }
        },
        async copyReferralLink() {
            if (!this.referralLink) {
                await this.getUserReferralCode();
            }
            
            // if (this.referralLink) {
            //     try {
            //     await navigator.clipboard.writeText(this.referralLink);
            //     this.$swal({
            //         text: "Referral link copied to clipboard!",
            //         icon: "success",
            //         customClass: {
            //             confirmButton: "btn bg-gradient-success",
            //         },
            //         buttonsStyling: false,
            //         })
            //     } catch (error) {
            //     console.error('Failed to copy referral link: ', error);
            //     }
            // }
        },

        async getUserReferralCode () {
            try {
                const profileService = new ProfileService()
                const response = await profileService.getReferralLink()

                if (response.data.data) {
                    this.referralLink = response.data.data;
                } 
                // else {
                // this.$swal({
                //     text: "No Referral Link Found",
                //     icon: "error",
                //     customClass: {
                //     confirmButton: "btn bg-gradient-success",
                //     },
                //     buttonsStyling: false,
                // })
                // }
            } catch (error) {
                console.log('Get user error: ', error)
            }
        },
    }
}
</script>
<style scoped>
#referralModal {
    z-index: 9999;
    
}

.btn {
    margin-bottom: 0;
}
</style>