<template>
    <div id="modalCreatePost" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCreatePost" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Update This Gnome’s Journey</h3>
              </div>
              <div class="card-body">
                <Form ref="createPost" role="form text-left" class="text-start" :validation-schema="createPostSchema" @submit="onSubmit" @invalid-submit="onInvalidSubmit">
                  <div v-if="!tokenAvailable" class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Gnome Code</label>
                    <Field 
                      id="code" 
                      type="text" 
                      placeholder="Gnome Code" 
                      name="code" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="code" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Title</label>
                    <Field 
                      id="title" 
                      type="text" 
                      placeholder="Title" 
                      name="title" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="title" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Description</label>
                    <Field 
                      id="description" 
                      type="text" 
                      placeholder="Description" 
                      name="description" 
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="description" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Attachment</label>
                    <Field 
                      id="attachment" 
                      type="file"
                      accept="image/*"
                      name="attachment"
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="attachment" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3">
                    <label>Pin Location (Optional)</label>
                    <Map ref="mapComponent" @handle-location-selected="handleLocationSelected" />
                  </div>
                  <soft-button 
                    color="light" 
                    variant="gradient" 
                    class="float-end mt-2"
                    data-bs-dismiss="modal"
                    @click="closeModal()"
                  >
                    <span>Close</span>
                  </soft-button>
                  <soft-button 
                    color="dark" 
                    variant="gradient"
                    class="float-end mt-2"
                  >
                    <span>Create</span>
                  </soft-button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Map from "../../../../components/Map/Map.vue";

export default {
    name: "CreatePost",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage,
        Map
    },
    props:{
      show:{
        type: Boolean,
        default: false
      }
    },
    emits: ['close-modal', 'create-post', 'invalid-creating-post'],
    data() {
        return {
            latitude: null,
            longitude: null,
            locationAddress: null,
            tokenAvailable: false,
            createPostSchema: yup.object({
                title: yup.string().required("Title is required"),
                description: yup.string(),
                attachment: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
                    return value.size <= 1024 * 1024 * 4
                })
            }),
            codeValidationSchema: yup.object({
                code: yup.string().required("Gnome Code is required")
            }),
            mergedValidationSchema: null
        }
    },
    watch: {
      show(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (this.$refs.mapComponent) {
              this.$refs.mapComponent.resizeMap();
            }
          });
        }
      }
    },
    mounted () {
      const gnomeToken = JSON.parse(localStorage.getItem('gnomeToken'))

      if (gnomeToken === null) {
        this.tokenAvailable = false
        this.createPostSchema = this.createPostSchema.concat(this.codeValidationSchema);
      } else {
          this.tokenAvailable = true
      }
    },
    methods: {
      handleLocationSelected (data) {
        this.latitude = data.lngLat.lat
        this.longitude = data.lngLat.lng
        this.locationAddress = data.address
      },
      closeModal () {
        this.$emit('close-modal')
      },
        onSubmit(values) {
           this.$refs.createPost.validate().then((result) => {
            if (result) {
              let payload = {
                title: values.title,
                description: values.description,
                attachment: values.attachment,
                latitude: this.latitude,
                longitude: this.longitude,
                address: this.locationAddress
              }

              if (this.tokenAvailable) {
                payload.gnome_verification_token = JSON.parse(localStorage.getItem('gnomeToken')).gnome_verification_token
                payload.gnome_verification_code = null
              } else {
                payload.gnome_verification_code = values.code
                payload.gnome_verification_token = null
              }

              this.$emit('create-post', payload)
            }
           })
        }
    }
};
</script>

<style scoped>
/* .modal-form-size{
  max-width: 60%;
} */
</style>