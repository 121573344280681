<template>
  <ReferralPopup />
  <div class="pt-3 container-fluid">
    <div class="row removable">
      <div class="col-lg-4">
        <dashboard-small-card
          :title="{
            title: 'Modify User Preferences',
            subtitle: 'Set notifications'
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground
          }"
          @click="$router.push({ name: 'ProfileInfo' })"
        />
      </div>
      <div class="col-lg-4">
        <h1 class=""></h1>
        <h3 class="">User Points {{ userPonts }}</h3>
        <h3 class="">User Referral Points {{ userReferralPonts }}</h3>
      </div>
      <div class="col-lg-4">
        <soft-button class="mb-4" @click="$router.push({ name: 'GnomeVerificationPage' })">Found a Gnome?</soft-button>
      </div>
    </div>
    <div class="row removable">
      <div class="col-xl-3 col-sm-6">
        <dashboard-small-card
          :title="{
            title: 'Your Recent Likes'
          }"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground
          }"
          @click="scrollToRef('userRecentReaction')"
        />
      </div>
      <div class="col-xl-3 col-sm-6">
        <dashboard-small-card
          :title="{
            title: 'Your gnomes',
            subtitle: 'Quantity'
          }"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground
          }"
          @click="scrollToRef('userGnomes')"
        />
      </div>
      <div class="col-xl-3 col-sm-6">
        <dashboard-small-card
          :title="{
            title: 'Admin Recent likes',
            subtitle: '5 Fun Gnomes'
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground
          }"
          @click="scrollToRef('adminRecentReaction')"
        />
      </div>
      <div class="col-xl-3 col-sm-6">
        <dashboard-small-card
          :title="{
            title: 'Get Started: Gnome Kits',
            subtitle: 'Gnome Kits Sale'
          }"
          :icon="{
            component: 'ni ni-cart',
            background: iconBackground
          }"
          @click="$router.push({ name: 'HomePage' })"
        />
      </div>
    </div>
    <div class="row removable">
      <div ref="userRecentReaction" class="col-xl-8">
        <soft-table
          :title="'Your Recent Likes:'"
          :is-action="true"
          :header-list="reactionHeaderList"
          :item-list="reactionItemList"
          :action-list="actionList"
          @action="action"
          @cell-click="cellClick"
        />
        <div class="d-flex justify-content-center mt-4">
          <soft-button v-if="reactionPage < reactionLastPage" class="mb-4" @click="seeMoreReactions()">See more recent likes</soft-button>
        </div>
      </div>
      <div class="col-xl-4">
        <side-add-container />
      </div>
    </div>
    <div ref="userGnomes" class="col-xl-8">
      <soft-table
        class="mt-4"
        :title="'Your Gnomes:'"
        :is-action="false"
        :header-list="gnomeHeaderList"
        :item-list="gnomeItemList"
        @cell-click="redirectToSocialPage"
      />
    </div>
    <div class="row removable mt-4">
      <div ref="adminRecentReaction" class="col-lg-8">
        <soft-table
          :title="'Admin Recent Likes:'"
          :is-action="true"
          :header-list="adminReactionHeaderList"
          :item-list="adminReactionItemList"
          :action-list="actionList"
          @action="action"
          @cell-click="cellClick"
        />
      </div>
      <div class="col-lg-4">
        <side-add-container />
      </div>
    </div>
    <div class="row removable">
      <div class="col-lg-12">
        <div class="row mt-4 removable">
          <div class="col-xl-4 col-sm-6">
            <side-add-container />
          </div>
          <div class="col-xl-4 col-sm-6">
            <advertisement-card
              :title="'E-Commerce Link'"
              :sub-title="'Get started with your own gnome today!'"
              :description="'Unique Gnomes Available'"
              @click="$router.push({ name: 'HomePage' })"
            />
          </div>
          <div class="col-xl-4 col-sm-6">
            <side-add-container />
          </div>
          <!-- <div class="row removable">
            <div class="col-lg-12">
              <div class="row removable">
                <div class="col-xl-3 col-sm-6">
                  <dashboard-small-card
                    :title="{
                      title: 'Swap Footer'
                    }"
                    :icon="{
                      component: 'ni ni-money-coins',
                      background: iconBackground
                    }"
                  />
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <footer class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>
<script>
import DashboardSmallCard from "./components/DashboardSmallCard.vue";
import AdvertisementCard from "./components/AdvertisementCard.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftTable from "@/components/SoftTable.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import DashboardService from "../../services/api/dashboard/DashboardService.js";
import SideAddContainer from "../pages/posting/components/SideAddContainer.vue";
import PointsService from "../../services/api/points/PointsService.js";
import utils from "../../utils.js";
import ReferralPopup from '../../examples/Components/Popups/ReferralPopup.vue'

export default {
  name: "DashboardDefault",
  components: {
    DashboardSmallCard,
    SoftButton,
    SoftTable,
    AdvertisementCard,
    SideAddContainer,
    ReferralPopup
  },
  data() {
    return {
      faHandPointer,
      faUsers,
      faCreditCard,
      faScrewdriverWrench,
      iconBackground: "bg-gradient-success",
      US,
      DE,
      BR,
      GB,
      adminReactionHeaderList: [
        { id: 1, headerName: 'Post', headerAlign: 'left', fieldType: 'image', field: ['postImage','postTitle','postDescription'], show: true},
        { id: 2, headerName: 'Gnome', headerAlign: 'left', fieldType: 'image', field: ['gnomeImage','gnomeName','gnomeBio'], show: true},
        { id: 3, headerName: 'Updated Date', headerAlign: 'center', fieldType: 'date', field: ['postCreatedAt'], show: true},
        { id: 4, headerName: 'Reaction Count', headerAlign: 'center', fieldType: 'number', field: ['postReactionCount'], show: true},
        { id: 5, headerName: 'Status', headerAlign: 'center', fieldType: 'bool', field: ['postActiveStatus'], show: true}
      ],
      adminReactionItemList: [],
      actionList: [
        { id: 1, name: 'More Info', icon: 'fas fa-eye text-secondary'}
      ],
      reactionItemList: [],
      reactionHeaderList: [
        { id: 1, headerName: 'Post', headerAlign: 'left', fieldType: 'image', field: ['postImage','postTitle','postDescription'], show: true},
        { id: 2, headerName: 'Gnome', headerAlign: 'left', fieldType: 'image', field: ['gnomeImage','gnomeName','gnomeBio'], show: true},
        { id: 3, headerName: 'Updated Date', headerAlign: 'center', fieldType: 'date', field: ['updatedDate'], show: true},
        { id: 4, headerName: 'Reaction Count', headerAlign: 'center', fieldType: 'number', field: ['postReactionCount'], show: true},
        { id: 5, headerName: 'Status', headerAlign: 'center', fieldType: 'bool', field: ['postActiveStatus'], show: true}
      ],
      reactionPage: 0,
      reactionLastPage: 0,
      gnomeItemList: [],
      gnomeHeaderList: [
        { id: 1, headerName: 'Gnome', headerAlign: 'left', fieldType: 'image', field: ['avatar','name','bio'], show: true},
        { id: 2, headerName: 'Updated Date', headerAlign: 'center', fieldType: 'date', field: ['updatedAt'], show: true},
        { id: 3, headerName: 'Status', headerAlign: 'center', fieldType: 'bool', field: ['status'], show: true}
      ],
      userPonts: 0,
      loggedUser: null,
      userReferralPonts: 0
    };
  },
  async mounted () {
    this.loggedUser = JSON.parse(utils.getLoggedUser())
    await this.getUserRecentReactions()
    await this.getUsersGnomes()
    await this.getAdminRecentReactions()
    await this.getUserPoints(this.loggedUser.id)
    await this.getReferralUserPoints()
  },
  methods: {
    redirectToSocialPage (data) {
      this.$router.push({ name: 'GnomeSocialPage', params: { id: data.item.id } })
    },
    action (data) {
      switch (data.action.id) {
        case 1:
          this.$router.push({ name: 'GnomePostViewPage', params: { id: data.item.postId } })
          break;
      
        default:
          break;
      }
    },
    cellClick (data) {
      switch (data.cell.id) {
        case 1:
        case 2:
        case 3:
        case 4:
          this.$router.push({ name: 'GnomePostViewPage', params: { id: data.item.postId } })
          break;
      
        default:
          break;
      }
    },
    async seeMoreReactions () {
      this.reactionPage = this.reactionPage + 1;

      if (this.reactionPage <= this.reactionLastPage) await this.getUserRecentReactions(this.reactionPage)
      else this.reactionPage = this.reactionLastPage

    },
    async getUserRecentReactions (page = 1) {
      try {
        this.$swal.showLoading();

        this.reactionPage = page

        const dashboardService = new DashboardService()
        const response = await dashboardService.getUserRecentReactions(page)

        this.reactionLastPage = this.reactionLastPage === 0 ? response.data.data.last_page : this.reactionLastPage

        const list = response.data.data.data.map((element) => {
          return {
            id: element.id,
            postCreatedAt: element.post.created_at,
            updatedDate: element.updated_date,
            postId: element.post.id,
            postImage: element.post.image,
            postDescription: element.post.description,
            postTitle: element.post.title,
            postReactionCount: element.post.reaction_count,
            postActiveStatus: element.post.active_status,
            gnomeImage: element.post.gnome.avatar,
            gnomeName: element.post.gnome.name,
            gnomeBio: element.post.gnome.bio
          }
        })

        this.reactionItemList = this.reactionItemList.concat(list)

        this.$swal.close();
      } catch (error) {
        console.log('User recent reaction getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getUsersGnomes () {
      try {
        this.$swal.showLoading();
        this.gnomeItemList = []

        const dashboardService = new DashboardService()
        const response = await dashboardService.getUsersGnomes()

        const list = response.data.data.data.map((element) => {
          return {
            id: element.id,
            createdAt: element.created_at,
            updatedAt: element.updated_at,
            avatar: element.avatar,
            bio: element.bio,
            name: element.name,
            status: element.status
          }
        })

        this.gnomeItemList = list

        this.$swal.close();
      } catch (error) {
        console.log('User gnome getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getAdminRecentReactions () {
      try {
        this.$swal.showLoading();
        this.adminReactionItemList = []

        const dashboardService = new DashboardService()
        const response = await dashboardService.getAdminRecentReactions()

        const list = response.data.data.map((element) => {
          return {
            id: element.id,
            postCreatedAt: element.post.created_at,
            updatedDate: element.updated_date,
            postId: element.post.id,
            postImage: element.post.image,
            postDescription: element.post.description,
            postTitle: element.post.title,
            postReactionCount: element.post.reaction_count,
            postActiveStatus: element.post.active_status,
            gnomeImage: element.post.gnome.avatar,
            gnomeName: element.post.gnome.name,
            gnomeBio: element.post.gnome.bio
          }
        })

        this.adminReactionItemList = list

        this.$swal.close();
      } catch (error) {
        console.log('Admin recent reaction getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    scrollToRef (ref) {
      switch (ref) {
        case 'userRecentReaction':
          this.$refs.userRecentReaction.scrollIntoView({ behavior: 'smooth' })
          break;
        
        case 'userGnomes':
          this.$refs.userGnomes.scrollIntoView({ behavior: 'smooth' })
          break;

        case 'adminRecentReaction':
          this.$refs.adminRecentReaction.scrollIntoView({ behavior: 'smooth' })
          break;
        
        default:
          break;
      }
    },
    async getUserPoints (userId) {
      try {
        this.$swal.showLoading();
        this.userPonts = 0

        const pointsService = new PointsService()
        const response = await pointsService.getUserPointsByUserId(userId)

        this.userPonts = response.data.total_points

        this.$swal.close();
      } catch (error) {
        console.log('User points getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getReferralUserPoints () {
      try {
        this.$swal.showLoading();
        this.userReferralPonts = 0

        let payload = {
          'user_id': this.loggedUser.id,
          'action': 'gnome_referral'
        }
        const pointsService = new PointsService()
        const response = await pointsService.getReferralPointsByUserId(payload)

        this.userReferralPonts = response.data.total_points

        this.$swal.close();
      } catch (error) {
        console.log('User points getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  }
};
</script>
  