<template>
  <div v-if="gnomeData">
    <div class="container-fluid">
      <!-- Header Section -->
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + gnomeData.cover_photo + ')',
          backgroundPositionY: '50%'
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                :src="gnomeData.avatar"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ gnomeData.name }}</h5>
            </div>
          </div>
          <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
              <soft-button
                class="btn btn-block btn-default mb-3 float-end mt-2 mb-0"
                data-bs-toggle="modal"
                data-bs-target="#modalCreatePost"
                size="sm"
                @click="createPostClicked"
              >
                <span>Found a Gnome ?</span>
              </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Main Content Section -->
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="overflow-scroll card">
            <div class="card-body d-flex">
              <!-- Story Avatars -->
              <div class="text-center col-lg-2 col-md-2 col-sm-3 col-4" @click="$router.push({ name: 'HomePage' })">
                <a href="javascript:;" class="avatar avatar-lg border-1 rounded-circle bg-gradient-success">
                  <i class="text-white fa fa-shopping-cart"></i>
                </a>
                <p class="mb-0 text-sm" style="margin-top: 6px">E-Commerce</p>
              </div>
              <template v-for="(user) in gnomePostUserList.filter((_month,idx) => idx < 5)" :key="user.userId">
                <story-avatar
                  v-if="user"
                  :image="user.userImage"
                  :name="user.userName"
                  :user-id="user.userId"
                  @avatar-click="filterByUserId"
                />
              </template>
              <div v-if="gnomePostUserList.length > 5" class="text-center col-lg-2 col-md-2 col-sm-3 col-4">
                <a 
                  href="javascript:;" 
                  class="avatar avatar-lg border-1 rounded-circle bg-gradient-success"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPostUserList"
                  @click="showUserListPopup"
                >
                  <i class="text-white fa fa-users"></i>
                </a>
                <p class="mb-0 text-sm" style="margin-top: 6px">All Users</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 row">
        <!-- Left Column: Posts Section -->
        <div class="col-12 col-lg-8 order-2 order-lg-1">
          <div v-if="gnomePostList.length > 0">
            <div v-for="(post,index) in gnomePostList" :key="post.postId">
              <post-card
                v-if="loggedUser"
                :post="post"
                :page-type="'GnomeSocialPage'"
                :logged-user="loggedUser"
                :post-flagged-reasons="postFlaggedReasons"
                @edited-post="editedPost"
                @create-comment="createComment"
                @edit-comment="editComment"
              />
              <div v-if="adBlocked" class="text-center">
                <p>Please disable your ad blocker to support us!</p>
              </div>
              <div v-else>
                <soft-google-ads
                  v-if="(index + 1)%3 === 0"
                  :ad-client="'ca-pub-3940256099942544'"
                  :ad-slot="'1234567890'"
                />
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h3>No Posts Available</h3>
          </div>
        </div>

        <!-- Right Column: Sticky Profile Section -->
        <div class="col-12 col-lg-4 order-1 order-lg-2">
          <div class="sticky-column">
            <gnome-bio-card
              :title="gnomeData.name"
              :description="gnomeData.bio"
              :qr-image="gnomeData.qr_code"
              :show-edit="gnomeData.userId === loggedUser.id || loggedUser.role === 'admin'"
              :dropdown="[
                { label: 'Edit', route: '/gnome/overview/' + this.$route.params.id }
              ]"
            />
            <side-add-container />
          </div>
        </div>
      </div>

      <create-post
        :show="showCreatePostPopup"
        @close-modal="$store.state.showSidenav = true"
        @create-post="createPost"
      />
      <post-user-list
        :user-list="gnomePostUserList"
        @item-click="filterByUserId"
        @close-modal="$store.state.showSidenav = true"
      />
    </div>
  </div>
</template>


  <script>
  import kal from "@/assets/img/kal-visuals-square.jpg";
  import marie from "@/assets/img/marie.jpg";
  import ivana from "@/assets/img/ivana-square.jpg";
  import bruce from "../../../assets/img/bruce-mars.jpg";
  import emma from "../../../assets/img/ivana-squares.jpg";
  import nick from "@/assets/img/team-3.jpg";
  import team1 from "../../../assets/img/team-1.jpg";
  import team2 from "../../../assets/img/team-2.jpg";
  import team3 from "../../../assets/img/team-3.jpg";
  import team4 from "../../../assets/img/team-4.jpg";
  import team5 from "../../../assets/img/team-5.jpg";
  import slackLogo from "../../../assets/img/small-logos/logo-slack.svg";
  import invisionLogo from "../../../assets/img/small-logos/logo-invision.svg";
  import PostCard from "./components/PostCard.vue";
  import StoryAvatar from "./components/StoryAvatar.vue";
  import GnomeBioCard from "./components/GnomeBioCard.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import CreatePost from "./components/CreatePost.vue";
  import SoftGoogleAds from "../../../components/SoftGoogleAds.vue";

  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import FileConverterMixin from "@/mixins/FileConverterMixin.js";
  import GnomePostingService from "../../../services/api/gnome/GnomePostingService.js";
  import GnomeService from "../../../services/api/gnome/GnomeService.js";
  import ProfileService from "../../../services/api/user/ProfileService.js";
  import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin.js";
  import SideAddContainer from "./components/SideAddContainer.vue";
  import PostUserList from "./components/PostUserList.vue";

  export default {
    name: "GnomeSocialPage",
    components: {
      GnomeBioCard,
      PostCard,
      StoryAvatar,
      SoftButton,
      CreatePost,
      SoftGoogleAds,
      SideAddContainer,
      PostUserList
    },
    mixins:[FileConverterMixin,GnomeDefaultDataMixin],
    data() {
      return {
        showCreatePostPopup: false,
        showMenu: false,
        nick,
        slackLogo,
        invisionLogo,
        kal,
        marie,
        ivana,
        bruce,
        emma,
        team1,
        team2,
        team3,
        team4,
        team5,
        gnomeData: null,
        gnomePostList: [],
        gnomePostUserList: [],
        currentPage: 0,
        lastPage: 1,
        loggedUser: null,
        postFlaggedReasons: [],
        adblocked: false
      };
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      await this.getLoggedUser()
      this.callScroll()
      //this.$store.state.showSidenav = false;
      await this.getPostFlaggedReasons()
      await this.getGnomeById(this.$route.params.id)
      await this.getNextPosts()
      await this.getGnomePostUserList(this.$route.params.id,6)
      setTimeout(() => {
        this.adblocked = this.checkAdBlocker()
      }, 2000);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      localStorage.removeItem('gnomeToken')
    },
    methods :{
      createPostClicked() {
        this.$store.state.showSidenav = false
        this.showCreatePostPopup = true
      },
      async showUserListPopup() {
        this.$store.state.showSidenav = false
        await this.getGnomePostUserList(this.$route.params.id)
      },
      editedPost (updatedPost) {
        const index = this.gnomePostList.findIndex(post => post.postId === updatedPost.id);

        if (index !== -1) {
          this.gnomePostList[index] = updatedPost;
        }
      },
      async createPost (data) {
        try {
          this.$swal.showLoading();

          this.$store.state.showSidenav = true

          const attachment = await this.readFileAsBase64(data.attachment)

          const payload = {
            title: data.title,
            description: data.description,
            image: attachment,
            gnome_id: this.$route.params.id,
            gnome_verification_token: data.gnome_verification_token,
            gnome_verification_code: data.gnome_verification_code,
            latitude: data.latitude,
            longitude: data.longitude,
            address: data.address
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.createPost(payload)

          this.gnomePostList.unshift({
            gnomeId: response.data.gnome_id,
            postId: response.data.id,
            image: response.data.image,
            title: response.data.title,
            description: response.data.description,
            userId: response.data.user.id,
            userName: response.data.user.first_name + ' ' + response.data.user.last_name,
            comments: []
          })

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully post created...!",
            type: 'success-message',
          }).then(() => {
            window.location.reload()
          })
        } catch (error) {
          console.log('Gnome Create Posting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else if((error.response.status === 403)) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getGnomePosts (id, page, userId) {
        try {
          this.$swal.showLoading();

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.getPostsByGnomeId(id, page, userId)
          response.data.data.forEach((element) => {
            const obj = {
              gnomeId: element.gnome_id,
              postId: element.id,
              image: element.image,
              title: element.title,
              description: element.description,
              userId: element.user.id,
              userImage: element.user.profile_picture,
              reaction_count: element.reaction_count,
              current_user_reacted: Array.isArray(element.current_user_reacted)&&element.current_user_reacted.length>0,
              userName: element.user.first_name + ' ' + element.user.last_name,
              comments: [],
              longitude: element.longitude,
              latitude: element.latitude,
              address: element.address,
              createdDate: element.created_at
            }
            element.comments.forEach((comment) => {
              obj.comments.push({
                commentId: comment.id,
                postId: comment.gnome_post_id,
                gnomeId: element.gnome_id,
                userId: comment.user.id,
                userName: comment.user.first_name + ' ' + comment.user.last_name,
                comment: comment.comment,
                userImage: comment.user.profile_picture
              })
            })
            this.gnomePostList.push(obj)
          })
          if (this.lastPage == 1 && response.data.last_page > 1) this.lastPage = response.data.last_page
          this.$swal.close();
        } catch (error) {
          console.log('Gnome posts getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Token Expired!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })

          setTimeout(() => {
              this.$router.push({ name: 'GnomeListPage'})
            }, 3000);
        }
      },
      extractIdFromPath() {
        const path = this.$route.path;
        const parts = path.split('/');
        return parts[parts.length - 1];
      },
      async getGnomeById (id) {
        try {
          this.$swal.showLoading();
          this.gnomeData = null

          const gnomeService = new GnomeService()
          const response = await gnomeService.getGnomeById(id)
          if (response.data.data) {
            this.gnomeData = {
              name: response.data.data.name,
              bio: response.data.data.bio,
              avatar: response.data.data.avatar,
              cover_photo: response.data.data.cover_photo,
              qr_code: response.data.data.qr_code,
              userId: response.data.data.user_id
            }
          }
          this.$swal.close();
        } catch (error) {
          console.log('Gnome posts getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async createComment (data) {
        try {
          this.$swal.showLoading();

          const payload = {
            gnome_post_id: data.postId,
            comment: data.comment
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.createComment(payload)

          this.gnomePostList.forEach((post) => {
            if (post.postId == data.postId) {
              post.comments.unshift({
                commentId: response.data.data.id,
                postId: response.data.data.gnome_post_id,
                gnomeId: post.gnomeId,
                userId: response.data.data.user.id,
                userName: response.data.data.user.first_name + ' ' + response.data.data.user.last_name,
                comment: response.data.data.comment,
                userImage: response.data.data.user.profile_picture
              })
            }
          })

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully comment created...!",
            type: 'success-message',
          })
        } catch (error) {
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getGnomePostUserList (id,limit,page) {
        try {
          this.$swal.showLoading();
          this.gnomePostUserList = []

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.getPostsUserListByGnomeId(id,limit,page)
          response.data.gnome_post_users.forEach((element) => {
            const obj = {
              userImage: element.profile_picture,
              userId: element.id,
              userName: element.first_name + ' ' + element.last_name
            }
            this.gnomePostUserList.push(obj)
          })
          this.$swal.close();
        } catch (error) {
          console.log('Gnome post users getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      callScroll () {
        try {
          window.onscroll = async () => {
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight

            if (bottomOfWindow) {
              await this.getNextPosts()
            }
          }
        } catch (err) {
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },

      async getNextPosts (userId = null) {
        try {
          this.currentPage = this.currentPage + 1

          if (this.currentPage <= this.lastPage) {
            await this.getGnomePosts(this.$route.params.id, this.currentPage, userId)
          }

        } catch (err) {
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async editComment (data) {
        try {
          this.$swal.showLoading();

          const payload = {
            comment_id: data.commentId,
            comment: data.comment
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.editComment(payload)

          const postIndex = this.gnomePostList.findIndex((post) => (post.postId == data.postId))
          const commentIndex = this.gnomePostList[postIndex].comments.findIndex((comment) => (comment.commentId == data.commentId))

          this.gnomePostList[postIndex].comments[commentIndex] = {
            commentId: response.data.id,
            postId: response.data.gnome_post_id,
            gnomeId: this.gnomePostList[postIndex].gnomeId,
            userId: response.data.user.id,
            userName: response.data.user.first_name + ' ' + response.data.user.last_name,
            comment: response.data.comment,
            userImage: response.data.data.user.profile_picture
          }

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully comment updated...!",
            type: 'success-message',
          })
        } catch (error) {
          console.log('Gnome edit comment Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getLoggedUser () {
        try {
          this.$swal.showLoading();

          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status,
            userImage: response.data.data.profile_picture
          }
          this.$swal.close();
        } catch (error) {
          console.log('Logged user Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getPostFlaggedReasons () {
        try {
          this.$swal.showLoading();
          this.postFlaggedReasons = []
          const arr = []

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.getPostFlaggedReasons()

          response.data.forEach((reason) => {
            arr.push({
              id: reason.id,
              reason: reason.name
            })
          })

          this.postFlaggedReasons = arr

          this.$swal.close();
        } catch (error) {
          console.log('Gnome comments getting Error: ', error)
          this.isCommentLoaded = true
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async filterByUserId (userId) {
        this.gnomePostList = []
        this.currentPage = 0
        await this.getNextPosts(userId)
      }
    }
  };
  </script>
  <style>
    @media (min-width: 992px) {
      .sticky-column {
        position: sticky;
        top: 20px;
      }
    }
  </style>
