<template>
  <navbar class="mt-3" />
  <main class="mt-0 main-content">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved9.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7">
            <div class="card">
              <div class="text-center card-body px-lg-5 py-lg-5">
                <div class="mb-4 text-center text-muted">
                  <h2>Email Verification</h2>
                </div>
                <div class="row gx-2 gx-sm-3">
                  <div
                    v-for="(part, index) in codeParts"
                    :key="index"
                    class="col"
                  >
                    <div class="form-group">
                      <input
                        :ref="'input-' + index"
                        v-model="codeParts[index]"
                        type="text"
                        class="form-control form-control-lg"
                        maxlength="1"
                        autocomplete="off"
                        autocapitalize="off"
                        @input="moveToNextInput(index)"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <soft-button
                    class="mt-0 mb-3"
                    variant="gradient"
                    color="warning"
                    full-width
                    @click="sendCode"
                    >Send code
                  </soft-button>
                  <soft-alert 
                    v-if="showAlert"
                    :color="colorAlert"
                    :dismissible="false"
                    :icon="iconAlert"
                  >
                    {{ msgAlert }}
                  </soft-alert>
                  <span class="text-sm text-muted"
                    >Haven't received it?<a class="resend-button" @click="resendCode">
                      <u> Resend a new code</u></a
                    >.</span
                  >
                  <div class="text-center">
                    <router-link :to="{ name: 'SignIn' }"
                      class="text-dark font-weight-bolder">
                      Sign in
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";
import { mapMutations } from "vuex";
import AuthService from "../../../services/api/auth/AuthService";
import SoftAlert from "@/components/SoftAlert.vue";

export default {
  name: "VerificationBasic",
  components: {
    Navbar,
    SoftButton,
    SoftAlert
  },
  data() {
    return {
      showAlert: false,
      codeParts: ["", "", "", ""], // Array to store the 4 code inputs
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    moveToNextInput(index) {
      if (this.codeParts[index].length === 1 && index < 3) {
        this.$refs[`input-${index + 1}`][0].focus();
      }
    },
    
    async resendCode () {
      try {
        this.showAlert = false

        const payload = {
          email: this.$route.query.email
        }
        const authService = new AuthService()
        await authService.resendVerification(payload)

        this.showAlert = true
        this.colorAlert = 'success'
        this.msgAlert = 'Verification code sent to your email'
      } catch (error) {
        this.showAlert = true
        this.msgAlert = error.response.data.message
        this.colorAlert = 'danger'

        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    },

    async sendCode() {
      try {
        this.showAlert = false
        const verificationCode = this.codeParts.join("");
        
        if (verificationCode.length !== 4) {
          this.showAlert = true
          this.msgAlert = 'Please enter a complete 4-digit code.'
          this.colorAlert = 'danger'

          setTimeout(() => {
            this.showAlert = false
          }, 4000)
          return;
        }

        const payload = {
          email: this.$route.query.email,
          verification_code: verificationCode
        }
        const authService = new AuthService()
        await authService.verifyEmail(payload)

        this.showAlert = true
        this.colorAlert = 'success'
        this.msgAlert = 'Successful Verification'

        setTimeout(() => {
            this.$router.push({ name: 'SignIn'})
          }, 3000)
      } catch (error) {
        this.showAlert = true
        this.msgAlert = error.response.data.message
        this.colorAlert = 'danger'

        setTimeout(() => {
          this.showAlert = false
        }, 4000)
      }
    },
  },
};
</script>
<style>
.resend-button {
  cursor: pointer;
}
</style>

