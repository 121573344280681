<template>
  <div id="modalPostUserList" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPostUserList" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-header pb-0 text-left">
              <h3 class="font-weight-bolder text-info text-gradient">Post User List</h3>
            </div>
            <div class="card-body">
              <div class="list-group">
                <template v-for="(user) in userList" :key="user.UserId">
                  <a class="list-group-item list-group-item-action d-flex align-items-center" data-bs-dismiss="modal" @click="$emit('item-click',user.userId);closeModal()">
                    <soft-avatar
                      v-if="user"
                      :img="user.userImage"
                      class="rounded-circle"
                    />
                    <span class="mx-2">{{ user.userName }}</span>
                  </a>
                </template>
              </div>
              <soft-button 
                color="light" 
                variant="gradient" 
                class="float-end mt-4"
                data-bs-dismiss="modal"
                @click="closeModal()"
              >
                <span>Close</span>
              </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
export default {
  name: "CreatePost",
  components: {
    SoftButton,
    SoftAvatar
  },
  props:{
    userList:{
      type: Array,
      default: () => []
    }
  },
  emits: ['close-modal', 'item-click'],
  data() {
    return {
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    }
  }
};
</script>

<style scoped>
.modal-form-size{
  max-width: 60%;
}
</style>